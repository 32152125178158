import { FeatureServices } from '@feature-hub/core';
import { Logger } from '@feature-hub/logger';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import {
  VueFormatterServiceInterfaceV1,
  ConsumptionsAndEmissions,
} from '@oneaudi/vue-formatter-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { Asset } from './EditorContentTypes';

export interface Dependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

export type AsyncStateHolder = (() => Promise<InitialState>) | InitialState | undefined;

export interface AsyncFeatureAppProps {
  readonly asyncStateHolder: AsyncStateHolder;
}

export interface InitialState {
  i18nMessages: I18nMessages;
  slides: SlideContent[];
}

export type I18nMessages = Record<I18nMessageKey, string>;

export type I18nMessageKey =
  | 'start'
  | 'pause'
  | 'previous'
  | 'next'
  | 'jump.to'
  | 'mute'
  | 'unmute';

export enum ScreenOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
}

export interface SsrWindow extends Partial<Window> {
  innerWidth: number;
  innerHeight: number;
}

export type UseWindow = Window | SsrWindow;

export type Content = {
  themeGallery: ThemeGallery;
  slides: SlideContent[];
};

export interface SlideContent {
  headline: string;
  subline?: string;
  asset?: Asset;
  wltp?: string[];
  additionalText?: string;
  wltpData?: ConsumptionsAndEmissions[] | undefined;
}

export enum ThemeGallery {
  LIGHT = 'light',
  DARK = 'dark',
}
export interface SerializedSlideProps {
  formattedConsumption: string | (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown> | undefined)[] | undefined;
  formattedCo2Class?: string | (string | Record<string, unknown>)[] | undefined;
  formattedDischargedConsumption?: string | (string | Record<string, unknown>)[] | undefined;
  formattedDischargedCo2Class?: string | (string | Record<string, unknown>)[] | undefined;
}

export interface ImageProps {
  url: string;
  alt?: string;
  testId: string;
  loading?: string;
}
