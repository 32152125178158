import React, { useContext } from 'react';
import styled from 'styled-components';

import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { AssetHelper } from '../helper/assetHelper';

import { SvgImage, Image } from './Image';
import Video from './Video';
import { FeatureAppContext } from '../context/FeatureAppContext';
import { AssetSlideProps } from './ComponentTypes';

export const StyledSlide = styled.div<{ active: boolean }>`
  ${({ active }) => active && `z-index: 1;`}
  position: relative;
  height: 48vh;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: calc(0.48 * var(--ue-viewport-height));
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    @media (orientation: portrait) {
      height: 65vh;
      .adobe-ue-edit &,
      .adobe-ue-preview & {
        // this variable is provided by the UE integration
        // it defines the initial viewport height in px
        // before the UE started resizing the iframe
        max-height: calc(0.65 * var(--ue-viewport-height));
      }
    }

    @media (orientation: landscape) {
      height: 100vh;
      .adobe-ue-edit &,
      .adobe-ue-preview & {
        // this variable is provided by the UE integration
        // it defines the initial viewport height in px
        // before the UE started resizing the iframe
        max-height: var(--ue-viewport-height);
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}px) and (orientation: landscape) {
      height: 100vh;
      .adobe-ue-edit &,
      .adobe-ue-preview & {
        // this variable is provided by the UE integration
        // it defines the initial viewport height in px
        // before the UE started resizing the iframe
        max-height: var(--ue-viewport-height);
      }
    }
  }
`;

const AssetSlide: React.FC<AssetSlideProps> = ({
  active,
  content: { asset },
  backgroundColor,
  trackId,
  index,
}) => {
  const additionalStyles = {
    ...(backgroundColor ? { backgroundColor } : null),
  };
  const { logger } = useContext(FeatureAppContext);

  return (
    <StyledSlide style={additionalStyles} active={active} id={trackId}>
      <UeContainer
        as="span"
        propertyPath={`slides.${index}.asset`}
        label="Asset"
        key={`container_asset_.${index}`}
      >
        <UeReference
          key={`ref_asset_.${index}`}
          propertyPath={`slides.${index}.asset`}
          behavior="component"
        >
          {asset && asset.assetType && asset.assetType === 'svg' && (
            <UeElement
              type="media"
              property="source"
              label="SvgImage"
              key={`element_asset_.${index}`}
            >
              {(ueElemProps) => (
                <SvgImage
                  src={AssetHelper.getImageUrl(asset.source?.assetValue, logger)}
                  alt={asset.alt}
                  testId="image"
                  loading="lazy"
                  {...ueElemProps}
                />
              )}
            </UeElement>
          )}
          {asset && asset.assetType && asset.assetType === 'image' && (
            <UeElement type="media" property="source" label="Image" key={`element_asset_.${index}`}>
              {(ueElemProps) => {
                return (
                  <Image
                    url={AssetHelper.getImageUrl(asset!.source?.assetValue, logger)}
                    alt={asset!.alt}
                    testId="image"
                    {...ueElemProps}
                  />
                );
              }}
            </UeElement>
          )}
          {asset && asset.assetType && asset.assetType === 'video' && (
            <UeElement type="media" property="source" label="Video" key={`element_asset_.${index}`}>
              {(ueElemProps) => <Video video={asset} active={active} {...ueElemProps} />}
            </UeElement>
          )}
        </UeReference>
      </UeContainer>
    </StyledSlide>
  );
};

export default AssetSlide;
