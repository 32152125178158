import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { audiDarkTheme, audiLightTheme, TokenizedTheme } from '@audi/audi-ui-react';
import { Content, InitialState, ThemeGallery } from '../FeatureAppTypes';
import { EditorSlideContent } from '../EditorContentTypes';
import { mapHeadlessContent } from '../utils/mapHeadlessContent';
import { FeatureAppContext } from './FeatureAppContext';
import { formatWltpData } from '../utils/mapToSlides';
import { getSlidesWithWltpData } from '../utils/stateUtils';

export interface ContentContextState {
  readonly theme: TokenizedTheme;
  readonly slides: EditorSlideContent[];
}

export interface ContentContextProviderProps extends PropsWithChildren {
  readonly initialState: InitialState;
}

export const ContentContext = createContext<ContentContextState>({} as ContentContextState);

export const ContentContextProvider: FC<ContentContextProviderProps> = ({
  initialState,
  children,
}: PropsWithChildren<ContentContextProviderProps>) => {
  const { contentService, localeService, logger, vueFormatterService } =
    useContext(FeatureAppContext);
  const initialContent = contentService.getContent();
  const [content, setContent] = useState<Content>(mapHeadlessContent(initialContent));
  const mappedSlideProps: EditorSlideContent[] = initialState.slides.map((slide) => {
    return {
      ...slide,
      wltpData: formatWltpData(slide?.wltpData),
    };
  });
  const [slides, setSlides] = useState<EditorSlideContent[]>(mappedSlideProps);
  const appTheme = content.themeGallery === ThemeGallery.DARK ? audiDarkTheme : audiLightTheme;
  const [theme, setTheme] = useState<TokenizedTheme>(appTheme);

  useEffect(() => {
    let mounted = true;

    const contentChangeListener = () => {
      if (mounted) {
        const newContent = contentService.getContent();
        const newMappedContent = mapHeadlessContent(newContent);
        setContent(newMappedContent);
        setTheme(
          newMappedContent.themeGallery === ThemeGallery.DARK ? audiDarkTheme : audiLightTheme,
        );
      }
    };

    contentService.onContentChange(contentChangeListener);

    return () => {
      mounted = false;
      contentService.removeOnContentChange(contentChangeListener);
    };
  }, []);

  useEffect(() => {
    if (content?.slides) {
      getSlidesWithWltpData(content, localeService, vueFormatterService, logger).then((data) => {
        const mappedSlides: EditorSlideContent[] = data.map((slide) => {
          return {
            ...slide,
            wltpData: formatWltpData(slide?.wltpData),
          };
        });
        setSlides(mappedSlides);
      });
    }
  }, [content.slides]);

  return useMemo(
    () => (
      <ContentContext.Provider
        value={{
          slides,
          theme,
        }}
      >
        {children}
      </ContentContext.Provider>
    ),
    [slides, theme],
  );
};
