import { isContentFragment } from '@oneaudi/falcon-tools';
import { AEMContent, AEMSlide, Asset, AssetSource } from '../EditorContentTypes';
import { Content, SlideContent, ThemeGallery } from '../FeatureAppTypes';

const getDefaultSlideContent = (): SlideContent => ({
  headline: 'Placeholder headline',
  subline: '',
  asset: undefined, // No asset for new slides
  wltp: [],
  additionalText: '',
});

const mediaPlaceholder =
  'https://delivery-p123727-e1214609.adobeaemcloud.com/adobe/assets/urn:aaid:aem:e149716b-bf13-467b-9d5c-70417b30b2b0/as/audi-logo-padding.png?preferwebp=true';

function validateSlides(slides: unknown): boolean {
  return Array.isArray(slides) && slides.every((slide) => isContentFragment(slide));
}

export const mapHeadlessContent = (content: Content | AEMContent): Content => {
  if (!content || !('__type' in content) || content.__type !== 'aem-headless') {
    return content as Content;
  }

  const slides = validateSlides(content.fields?.slides)
    ? content.fields.slides.map(mapAEMSlideToSlideContent)
    : [getDefaultSlideContent()];

  const normalizedContent: Content = {
    themeGallery: content.fields?.themeGallery === 'light' ? ThemeGallery.LIGHT : ThemeGallery.DARK,
    slides,
  };

  return normalizedContent;
};

export const mapAEMSlideToSlideContent = (aemSlide: AEMSlide): SlideContent => {
  // Extract fields
  const { headline, subline, wltp, additionalText } = aemSlide.fields || {};
  const assetFields = aemSlide?.fields?.asset?.fields ?? {};

  // Check if an asset fragment exists
  const hasAssetFragment = aemSlide?.fields?.asset && isContentFragment(aemSlide.fields.asset);
  const {
    assetType = assetFields.assetType ?? 'image',
    alt = assetFields.alt ?? 'Placeholder alt text',
    backgroundColor = assetFields.backgroundColor ?? '',
    source = assetFields.source,
    sourceMobile = assetFields.sourceMobile,
    title = 'Placeholder title',
    autoPlay = false,
    loop = false,
    hideMuteButton = false,
    svgUrl = '',
  } = assetFields;

  let asset: Asset | undefined;
  if (hasAssetFragment) {
    // If there's a valid asset content fragment, create the asset object with placeholders
    let assetValue: AssetSource = { image: [] };
    let mobileAssetValue: AssetSource | undefined;

    // Ensure source is a valid path or URL
    const sourcePath =
      typeof source === 'object' && 'path' in source ? source.path : (source as string);

    const sourceMobilePath =
      typeof sourceMobile === 'object' && 'path' in sourceMobile
        ? sourceMobile.path
        : (sourceMobile as string);

    switch (assetType) {
      case 'image':
      case 'svg':
        assetValue = {
          image: [
            {
              imageFormats: {
                'web-largest': mediaPath(sourcePath, mediaPlaceholder),
              },
            },
          ],
        };
        break;
      case 'video':
        assetValue = mediaPath(sourcePath, mediaPlaceholder);
        mobileAssetValue = mediaPath(sourceMobilePath, mediaPlaceholder);
        break;
      default:
        // console.warn('Unknown assetType:', assetType);
        break;
    }

    asset = {
      assetType,
      source: { assetValue },
      sourceMobile: { assetValue: mobileAssetValue ?? assetValue },
      alt,
      backgroundColor,
      title,
      autoPlay,
      loop,
      hideMuteButton,
      svgUrl,
    };
  }

  return {
    headline: headline ?? 'Placeholder headline',
    subline: subline ?? '',
    asset, // will be undefined for new slides
    wltp: wltp ?? [],
    additionalText: additionalText ?? '',
  };
};

function mediaPath(path: string, placeholder: string) {
  return path || placeholder;
}
