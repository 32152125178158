import React, { useMemo } from 'react';
import { FeatureAppContextProviderProps, FeatureAppContextState } from './ContextTypes';

export const FeatureAppContext = React.createContext<FeatureAppContextProviderProps>(
  {} as FeatureAppContextProviderProps,
);

export const FeatureAppContextProvider = ({
  featureServices,
  children,
}: FeatureAppContextState): JSX.Element => {
  const {
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'locale-service': localeService,
    'dbad:audi-i18n-service': i18nService,
    's2:logger': logger,
    'audi-tracking-service': trackingService,
    'audi-content-service': contentService,
    'audi-render-mode-service': renderModeService,
  } = featureServices;

  const state = useMemo(() => {
    return {
      vueFormatterService,
      localeService,
      i18nService,
      logger,
      trackingService,
      contentService,
      renderModeService,
    };
  }, []);

  return <FeatureAppContext.Provider value={state}>{children}</FeatureAppContext.Provider>;
};
