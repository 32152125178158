import { Logger } from '@feature-hub/logger';
import {
  FootnoteReference,
  getConsumptionsAndEmissionsWithIdentifiers,
} from '@oneaudi/feature-app-utils';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

import { createElement, ReactNode } from 'react';
import { I18nMessages, InitialState, Content, SlideContent } from '../FeatureAppTypes';
import { fetchI18nMessages } from '../i18n';

let i18nMessages: I18nMessages;

async function processSlides(
  content: Content,
  localeService: LocaleServiceV1,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  logger?: Logger,
): Promise<SlideContent[]> {
  const initialSlides = await Promise.allSettled(
    (content?.slides || []).map(async (slide): Promise<SlideContent> => {
      const consumptionAndEmissionData = slide?.wltp
        ? await getConsumptionsAndEmissionsWithIdentifiers(
            slide.wltp,
            vueFormatterService,
            localeService,
            logger,
          )
        : undefined;

      const consumptionAndEmissionArray =
        consumptionAndEmissionData && Object.keys(consumptionAndEmissionData).length > 0
          ? Object.values(consumptionAndEmissionData)
          : undefined;

      return {
        ...slide,
        wltpData: consumptionAndEmissionArray,
      };
    }),
  );

  return initialSlides
    .filter(
      (result): result is PromiseFulfilledResult<SlideContent> => result.status === 'fulfilled',
    )
    .map(({ value }) => value);
}

export async function createInitialState(
  content: Content,
  localeService: LocaleServiceV1,
  i18nService: I18NServiceV1,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  logger?: Logger,
): Promise<InitialState> {
  if (!i18nMessages) {
    i18nMessages = await fetchI18nMessages(localeService, i18nService);
  }

  const slides = await processSlides(content, localeService, vueFormatterService, logger);

  return { i18nMessages, slides };
}

export async function getSlidesWithWltpData(
  content: Content,
  localeService: LocaleServiceV1,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  logger?: Logger,
): Promise<SlideContent[]> {
  return processSlides(content, localeService, vueFormatterService, logger);
}

const deserializeReactNodeArrayInSlides = (slides: SlideContent[]) => {
  return slides.map((slide) => {
    const wltpData = slide.wltpData?.map((wltpItem) => {
      return {
        formattedConsumption: deserializeReactNodeArray(wltpItem.formattedConsumption),
        formattedEmission: deserializeReactNodeArray(wltpItem.formattedEmission),
        formattedCo2Class: deserializeReactNodeArray(wltpItem.formattedCo2Class),
        formattedDischargedConsumption: deserializeReactNodeArray(
          wltpItem.formattedDischargedConsumption,
        ),
        formattedDischargedCo2Class: deserializeReactNodeArray(
          wltpItem.formattedDischargedCo2Class,
        ),
      };
    });

    return {
      ...slide,
      wltpData,
    };
  });
};

export function deserializeState(state: string): InitialState {
  const initialState = JSON.parse(state);
  return {
    ...initialState,
    slides: deserializeReactNodeArrayInSlides(initialState.slides),
  };
}

export const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[],
): undefined | string | ReactNode[] => {
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(FootnoteReference, serializedReactNode?.props as undefined);
  });
};
