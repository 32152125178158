import { useEffect, useState } from 'react';
import { ScreenOrientation } from '../FeatureAppTypes';

export const getOrientation = (): ScreenOrientation => {
  const [orientation, setOrientation] = useState<ScreenOrientation>(ScreenOrientation.portrait);

  useEffect(() => {
    const updateOrientation = () => {
      setOrientation(
        window.matchMedia('(orientation: portrait)').matches
          ? ScreenOrientation.portrait
          : ScreenOrientation.landscape,
      );
    };

    updateOrientation();

    window.addEventListener('resize', updateOrientation);
    return () => window.removeEventListener('resize', updateOrientation);
  }, []);

  return orientation;
};

export const isLandscape = () => getOrientation() === ScreenOrientation.landscape;
export const isPortrait = () => getOrientation() === ScreenOrientation.portrait;
