import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { audiDarkTheme, audiLightTheme, ThemeProvider } from '@audi/audi-ui-react';
import { useContent } from '@oneaudi/feature-app-utils';
import { AssetHelper } from '../helper/assetHelper';
import { useIntersection } from '../utils/use-intersection';
import { useOrientation } from '../utils/use-orientation';
import { isPortrait } from '../utils/get-orientation';
import { Icon, IconName } from './Icons';
import { FeatureAppContext } from '../context/FeatureAppContext';
import { Content, ThemeGallery } from '../FeatureAppTypes';
import { VideoProps } from './ComponentTypes';
import { useI18n } from '../i18n';

const StyledVideo = styled.video`
  z-index: -1;
  position: absolute;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  overflow: hidden;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

const VideoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

const VideoControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
  padding: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) and (orientation: landscape) {
    justify-content: flex-end;
  }
`;

const VideoButton = styled.button`
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 48px;
    height: 48px;

    &:nth-child(2) {
      margin-left: var(${(props) => props.theme.responsive.spacing.l});
    }
  }
`;

const Video: React.FC<VideoProps> = ({
  active,
  video: { source, sourceMobile, loop, autoPlay, title, hideMuteButton },
}) => {
  const { logger } = useContext(FeatureAppContext);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(autoPlay);
  const [src, setSource] = useState<string>();
  const videoContainer = useRef<HTMLDivElement>(null);
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const themeGallery = useContent<Content>()?.themeGallery;
  const theme = themeGallery === ThemeGallery.DARK ? audiDarkTheme : audiLightTheme;

  // i18n translations
  const ariaTextPlayIcon = playing ? useI18n('pause') : useI18n('start');
  const ariaTextSoundIcon = muted ? useI18n('unmute') : useI18n('mute');

  const entry = useIntersection({
    node: videoContainer,
    rootMargin: '0% 0% -10% 0%',
  });

  const { orientation } = useOrientation();

  // Fallback for backwards compatibility, if mobile src is not available
  const srcMobile =
    sourceMobile?.assetValue !== '' && sourceMobile?.assetValue !== undefined
      ? sourceMobile?.assetValue
      : source?.assetValue;

  const url = AssetHelper.getVideoUrl(isPortrait() ? srcMobile : source?.assetValue, logger);

  useEffect(() => {
    if (active && entry?.isIntersecting) {
      // the hash at the end of the URL fixes the bug on iOS that the first
      // frame is not used as poster image
      setSource(`${url}#t=0.001`);
      if (autoPlay) {
        setTimeout(() => playVideo(), 500);
      }
    } else if (videoPlayer.current && playing) {
      videoPlayer.current.pause();
      setPlaying(false);
    }
  }, [active, entry, orientation]);

  const playVideo = () => {
    videoPlayer.current?.play().then(() => {
      setPlaying(true);
    });
  };

  const pauseVideo = () => {
    if (playing) {
      videoPlayer.current?.pause();
      setPlaying(false);
    }
  };

  const togglePlay = () => {
    if (playing) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  return (
    <ThemeProvider theme={theme}>
      <VideoContainer ref={videoContainer}>
        <StyledVideo
          key={src}
          ref={videoPlayer}
          loop={loop}
          muted={muted}
          playsInline
          title={title}
        >
          <source src={src} data-testid="videosrc" />
        </StyledVideo>
        <VideoControls>
          <VideoButton onClick={togglePlay} aria-label={ariaTextPlayIcon}>
            <Icon
              name={playing ? IconName.Pause : IconName.Play}
              color={theme.colors.ui.inverted}
              responsive
            />
          </VideoButton>
          {!hideMuteButton && (
            <VideoButton onClick={toggleMute} aria-label={ariaTextSoundIcon}>
              <Icon
                name={muted ? IconName.AudioOff : IconName.AudioOn}
                color={theme.colors.ui.inverted}
                responsive
              />
            </VideoButton>
          )}
        </VideoControls>
      </VideoContainer>
    </ThemeProvider>
  );
};

export default Video;
