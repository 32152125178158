/* eslint-disable consistent-return */
import { ThemeProvider } from '@audi/audi-ui-react';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import React, { useContext, useEffect, useState } from 'react';
import GallerySection from './components/Gallery';

import { FeatureAppContext } from './context/FeatureAppContext';
import { AsyncFeatureAppProps, InitialState } from './FeatureAppTypes';
import { I18nContextProvider } from './i18n/i18nContext';

import { createInitialState } from './utils/stateUtils';
import { mapHeadlessContent } from './utils/mapHeadlessContent';
import { ContentContext, ContentContextProvider } from './context/ContentContext';

const ProductHighlightsModule = () => {
  const { contentService, renderModeService } = useContext(FeatureAppContext);
  const { theme } = useContext(ContentContext);

  return (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <ThemeProvider theme={theme}>
        <GallerySection />
      </ThemeProvider>
    </UniversalEditorProvider>
  );
};

const AsyncFeatureApp: React.FunctionComponent<AsyncFeatureAppProps> = ({ asyncStateHolder }) => {
  const { localeService, i18nService, logger, vueFormatterService, contentService } =
    useContext(FeatureAppContext);

  const [state, setState] = useState<InitialState | undefined>(
    // when asyncStateHolder is an object it represents the serialized state
    // coming from the server ready to be used as the initial state
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  const initialContent = contentService.getContent();
  const content = mapHeadlessContent(initialContent);

  if (!initialContent) return;

  useEffect(() => {
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((newState) => setState(newState));
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, [asyncStateHolder]);

  useEffect(() => {
    const isMounted = { current: true };

    if (state && content?.slides) {
      createInitialState(content, localeService, i18nService, vueFormatterService, logger).then(
        (data) => {
          if (isMounted.current) {
            setState(data);
          }
        },
      );
    }

    return () => {
      isMounted.current = false;
    };
  }, [initialContent]);

  if (!state) {
    return null;
  }

  return (
    <ContentContextProvider initialState={state}>
      <I18nContextProvider i18nMessages={state.i18nMessages}>
        <ProductHighlightsModule />
      </I18nContextProvider>
    </ContentContextProvider>
  );
};

export default AsyncFeatureApp;
