import React from 'react';
import styled from 'styled-components';
import { LayoutItem, Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
// import { UeElement } from '@oneaudi/falcon-tools';
import { TextSlideProps } from './ComponentTypes';

const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

const StyledCopy = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-right: var(${(props) => props.theme.responsive.spacing.xl});
  }
`;

const StyledFooter = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0
    var(${(props) => props.theme.responsive.spacing.l}) 0;
`;

// eslint-disable-next-line react/no-unused-prop-types
// eslint-disable-next-line @typescript-eslint/no-shadow
const WltpText: React.FC<{ testId: string; children?: React.ReactNode }> = ({
  children,
  testId,
}) => {
  return (
    <Text data-testid={testId} as="p" variant="copy2">
      {/* <UeElement type="text" property="additionalText" label="Additional text"> */}
      {children}
      {/* </UeElement> */}
    </Text>
  );
};

const TextSlide: React.FC<TextSlideProps> = ({ content, testId, ...ueProps }) => {
  const { wltpData } = content;

  const wltpItems = wltpData
    ? wltpData?.map((wltpItem, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <WltpText key={`con-${i}`} testId={`wltp-con-${testId}`}>
          {wltpItem.formattedConsumption}
          {'; '}
          {wltpItem.formattedEmission}
          {wltpItem.formattedCo2Class && (
            <>
              {'; '}
              {wltpItem.formattedCo2Class}
            </>
          )}
          {wltpItem.formattedDischargedConsumption && (
            <>
              {'; '}
              {wltpItem.formattedDischargedConsumption}
            </>
          )}
          {wltpItem.formattedDischargedCo2Class && (
            <>
              {'; '}
              {wltpItem.formattedDischargedCo2Class}
            </>
          )}{' '}
        </WltpText>
      ))
    : [];

  if (content.additionalText) {
    wltpItems.push(
      <WltpText key="alt-phm" testId={`wltp-alt-${testId}`}>
        {renderTextWithFootnotesReferencesV2(content.additionalText)}
      </WltpText>,
    );
  }
  return (
    <StyledSlide data-testid={testId} {...ueProps}>
      <StyledCopy>
        <Text as="h3" variant="order3" data-testid={`headline-${testId}`} spaceStackEnd="s">
          {/* <UeElement type="text" property="headline" label="Headline"> */}
          {renderTextWithFootnotesReferencesV2(content.headline)}
          {/* </UeElement> */}
        </Text>
        {content.subline && (
          <Text as="p" variant="copy1" data-testid={`subline-${testId}`} spaceStackEnd="m">
            {/* <UeElement type="text" property="subline" label="Subline"> */}
            {renderTextWithFootnotesReferencesV2(content.subline)}
            {/* </UeElement> */}
          </Text>
        )}
      </StyledCopy>
      <StyledFooter>
        <LayoutItem>{wltpItems}</LayoutItem>
      </StyledFooter>
    </StyledSlide>
  );
};

export default TextSlide;
